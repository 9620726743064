<template>
  <header id="header" class="text-block" :class="{ 'header_toggle': scrolled }" v-show="handleScroll">
    <nav>
      <div class="container">
        <button class="toggle-axtn" :class="{ pressed: !addClass }" @click="HandelAddClass()" id="navBtnToggler"></button>
        <!-- <router-link to="/" @click="moveUp" class="logo">
          <img :src="themeSettingsData.site_logo" alt="">
        </router-link> -->
        <router-link to="/" class="logo">
          <img :src="themeSettingsData.site_logo" alt="">
        </router-link>

        <div class="nav-list" :class="{ nav_active: addClass }" id="navSideList">
          <ul>
            <li v-for="(row) in headerMenuData" :key="row">
              <!-- <a :href="row.url" @click="moveUp">{{ row.title }}</a> -->
              <router-link :to="row.url" @click="moveUp">
                {{ row.title }}
              </router-link>
            </li>

          </ul>
        </div>
        <template v-for="(row) in contactUsMenu" :key="row">
          <!-- <router-link :to="row.url" @click="moveUp" class="more-btn yelw-cta"> -->
          <!-- <router-link :to="row.url" class="more-btn yelw-cta">
            {{ row.title }}
          </router-link> -->
          <a :href="row.url" class="more-btn blue-cta" target="_blank">
            {{ row.title }}
          </a>
        </template>
      </div>
    </nav>
  </header>
</template>

<script>

import { mapState, mapActions } from 'vuex';
export default {
  name: "app",
  data() {
    return {
      limitPosition: 50,
      scrolled: false,
      lastPosition: 0,
      addClass: false,

    };
  },
  computed: {
    ...mapState({
      headerMenuData: state => state.webapiMod.headerMenuData,
      themeSettingsData: state => state.webapiMod.themeSettingsData,
      contactUsMenu: state => state.webapiMod.contactUsMenu,
    }),
  },
  watch: {
    themeSettingsData() {
      // console.log('AAAA',this.themeSettingsData);
    },
  },
  methods: {
    ...mapActions({
      GetThemeSettingsAndMenuData: 'webapiMod/GetThemeSettingsAndMenuData',
      // GetHeaderMenuData: "webapiMod/GetHeaderMenuData",
      // GetThemeSettingsData: 'webapiMod/GetThemeSettingsData',
      // GetContactUsMenu: 'webapiMod/GetContactUsMenu',
    }),
    HandelAddClass() {
      this.addClass = !this.addClass;


    },
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }

      this.lastPosition = window.scrollY;
      // this.scrolled = window.scrollY > 250;
    },
    moveUp() {
      this.addClass = !this.addClass;
    },
  },
  mounted() {
    this.GetThemeSettingsAndMenuData();

    // this.GetThemeSettingsData();
    // const getHeaderMenuDataBySlug = {
    //   menu_slug: 'header_menu',
    // };
    // this.GetHeaderMenuData(getHeaderMenuDataBySlug);
    // const getcontactUsMenuBySlug = {
    //   menu_slug: 'contactus_menu',
    // };
    // this.GetContactUsMenu(getcontactUsMenuBySlug);
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>