<template>
    <div class="mnht off-white">
        <div v-if="isload">
            <div  class="banner fix-top banner-white">
                <div class="container">
                    <div class="wrapper">
                        <div class="divider">
                            <div class="text-area">
                                <h2 v-html="banner_sec.banner_section_heading"></h2>
                                <p v-html="banner_sec.banner_section_content"></p>
                                <a :href="banner_sec.banner_section_button_url" class="btn-ik yelw-cta">
                                    {{ banner_sec.banner_section_button_name }} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow">
                                </a>
                                <div class="btm-bnr-icon">
                                  <div class="icon-bnr-devider">
                                     <h5>{{banner_sec.supported_networks_heading}} </h5>
                                     <div class="icn-flex">
                                       <div v-for="(row , index) in banner_sec.supported_networks_image" :key="index">
                                        <a :href="row.url" target="_blank"> <img :src="row.image" alt="Icon"></a></div>
                                        
                                     
                                     </div>
                                  </div>
                                  <div class="icon-bnr-devider">
                                     <h5>{{banner_sec.supported_stablecoins_heading}} </h5>
                                     <div class="icn-flex">
                                       <div v-for="(row , index) in banner_sec.supported_stablecoins_image" :key="index">
                                        <a :href="row.url" target="_blank"> <img :src="row.image" alt="Icon"></a></div>
                                      
                                     </div>
                                  </div>
                                </div>          
                            </div>
                        </div>
                    
                        <div class="divider">
                            <div class="brn-ser img430" v-if="banner_sec">
                                <!-- <v-lazy-image :src="banner_sec.banner_section_right_image" /> -->
                                <!-- <img src="@/assets/images/banner-img.png" alt=""> -->
                                <!-- <img :src="banner_sec.banner_section_right_image" alt=""> -->
                                <!-- <img v-lazy="banner_sec.banner_section_right_image"  alt="Bannger Image"> -->
                                <img :src="banner_sec.banner_section_right_image" alt="home banner">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
                <div class="partner-logo">
                    <div class="container">
                        <div class="partner-logo-sec">
                            <div class="flex logo-brdr" v-for="(row , index) in banner_sec.banner_section_bottom_content" :key="index">
                                <h3>{{ row.number }}</h3>
                                <p>{{ row.text }}</p>
                            </div>
                
                        </div>
                    </div>
                </div>
                <div class="container">
                  <div class="video-sec" v-if="works_video_section">
                     <h2>{{ works_video_section.works_video_section_heading }}</h2>
                     <div class="video-size">

                            <div class="video-container">
                                <!-- <img :src="works_video_section.works_video_section_video_image" width="1023" height="589"  alt="Poster" class="responsive-img" id="video-poster"> -->
                                <iframe :src="works_video_section.works_video_section_video_url" id="video-iframe" width="1023" height="350" style="display:none"></iframe>
                            </div>

                      </div>
                    
                  </div>
                   <div class="permision" v-if="permissioned_pools">
                        <h2>{{ permissioned_pools.permissioned_pools_section_heading }}</h2>
                        <div class="credit-polls-sec">
                            <div class="credit-poll" v-for="(row , index) in permissioned_pools.permissioned_pools_section_content" :key="index">
                                    <div class="img-sec">
                                        <img :src="row.image" alt="Industry"/>
                                        <!-- <div class="tag-line">Pool {{index+1}}</div> -->
                                    </div>
                                    <h3>{{ row.title}}</h3>
                                    <h4 v-html="row.desciption"></h4>
                                    <div class="list-poll">
                                    <ul>
                                        <li><img src="../../assets/images/icon-1.png" alt="Pool"/> Pool: {{ row.pool }}</li>
                                        <li><img src="../../assets/images/icon-2.png" alt="apr"/> APR: {{ row.apr }}</li>
                                        <li><img src="../../assets/images/icon-4.png" alt="credit"/> Credit Rating: {{ row.credit_rating }}</li>
                                        <li><img src="../../assets/images/icon-3.png" alt="tenture"/> Tenure: {{ row.tenure }}</li>
                                    </ul>
                                    </div>

                            </div>
                           
                        </div>
                   </div>
                   <div class="permision gap-top" v-if="institutional_pools">
                        <h2>{{ institutional_pools.institutional_pools_section_heading }}</h2>
                        <div class="credit-polls-sec">
                            <div class="credit-poll" v-for="(row , index) in institutional_pools.institutional_pools_section_content" :key="index">
                                    <div class="img-sec">
                                        <img :src="row.image" alt="Solar"/>
                                        <!-- <div class="tag-line">Pool {{ index+1 }}</div> -->
                                    </div>
                                    <h3>{{ row.title}}</h3>
                                    <h4 v-html="row.desciption"></h4>
                                    <div class="list-poll">
                                    <ul>
                                        <li><img src="../../assets/images/icon-1.png" alt="pool"/> Pool: {{ row.pool }}</li>
                                        <li><img src="../../assets/images/icon-2.png" alt="apr"/> APR: {{ row.apr }}</li>
                                        <li><img src="../../assets/images/icon-4.png" alt="credit"/> Credit Rating: {{ row.credit_rating }}</li>
                                        <li><img src="../../assets/images/icon-3.png" alt="tenture"/> Tenure: {{ row.tenure }}</li>
                                     </ul>
                                    </div>

                            </div>
                            
                        </div>
                        <div class="text-center gap-top gap-bottom">
                            <a :href="institutional_pools.institutional_pools_section_button_url" target="_blank" class="explore-btn btn-ik yelw-cta">
                            {{ institutional_pools.institutional_pools_section_button_name }}<img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow">
                     </a>
                        </div>
                   </div>
               
            </div>  
            <div class="lend-sec">
              <div class="container" v-if="lend_usdc_and_usdt">
                 <h2>{{ lend_usdc_and_usdt.lend_usdc_and_usdt_heading}}</h2>
                 <p v-html="lend_usdc_and_usdt.lend_usdc_and_usdt_desciption" class="text-center"></p>
                
                 <HomeTabs :tabs="['Lender', 'Borrower']">
                    <template #tab-0>
                     <div>
                        <div class="tab-section-list">
                            <div class="box-tab" v-for="(row , index) in lend_usdc_and_usdt.lender_content" :key="index">
                                <div class="box-text">
                                <h3>{{ row.heading }}</h3>
                                <div v-html="row.desciption"> </div>
                                </div>
                                <div class="box-img">
                                <img :src="row.image" alt="Generate-image"/>
                                </div>
                            </div>
                            
                        </div>
                        <div class="text-center gap-top">
                            <router-link :to="lend_usdc_and_usdt.lend_usdc_and_usdt_button_url" class="explore-btn btn-ik yelw-cta">
                                {{ lend_usdc_and_usdt.lend_usdc_and_usdt_button_name }}  <img src="@/assets/images/icon/arrow_right_blck.svg" alt="right-arrow">
                            </router-link>
                        </div>
                     </div>
                    </template>
                    <template #tab-1>
                        <div>
                            <div class="tab-section-list">
                                <div class="box-tab" v-for="(row , index) in lend_usdc_and_usdt.borrower_content" :key="index">
                                    <div class="box-text">
                                        <h3>{{ row.heading }}</h3>
                                        <div v-html="row.desciption"> </div>
                                    </div>
                                    <div class="box-img">
                                        <img :src="row.image" alt="Cost-capital"/>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="text-center gap-top">
                                <router-link :to="lend_usdc_and_usdt.borrower_usdc_and_usdt_button_url" class="explore-btn btn-ik yelw-cta">
                                  {{ lend_usdc_and_usdt.borrower_usdc_and_usdt_button_name }}  <img src="@/assets/images/icon/arrow_right_blck.svg" alt="right-arrow">
                                </router-link>
                            </div>
                        </div>
                    </template>
                </HomeTabs>
                 
                
                </div>
                <div class="backers-sec">
                  <div class="container" v-if="our_backers">
                    <h3>{{ our_backers.our_backers_section_heading }}</h3>
                    <div class="logo-sec-partner">
                      <div v-for="(row , index) in our_backers.our_backers_section_logo" :key="index">
                       <a :href="row.url" target="_blank"> <img :src="row.logo" alt="Partner-Factor"/></a>
                    </div>
                      <!-- <div><img src="../../assets/images/backers-logo2.png" alt="Partner-Gatecap"/></div>
                      <div><img src="../../assets/images/backers-logo3.png" alt="Partner-Airport"/></div>
                      <div><img src="../../assets/images/backers-logo4.png" alt="Partner-Msl"/></div> -->
                    </div>
                  </div>
                </div>
                <div class="container testimonial-heading" v-if="testimonials">
                    <h2>{{testimonials.testimonial_heading}}</h2>
                    <div class="testimonial-sec">
                     <TestimonialsSlider :testimonialsdata="testimonials.testimonials" /> 
                  </div>
                </div>
            </div>

            <div class="earn-sec">
                <div class="container">
                  <div class="earn-flex" v-if="earn_up">
                    <div class="left-text" >
                            <h4 v-html="earn_up.earn_up_section_title"></h4>
                            <h2> {{ earn_up.earn_up_section_subtitle }}</h2>
                            <p v-html="earn_up.earn_up_section_desciption"></p>
                            <a :href="earn_up.earn_up_section_button_url" class="explore-btn btn-ik yelw-cta">{{ earn_up.earn_up_section_button_name }} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow"></a>
                    </div>
                    <div class="earn-img">
                      <img :src="earn_up.earn_up_section_image" alt="usdc-image"/>
                    </div>
                </div>
                </div>
            </div>

            <div class="blog-sec">
                <div class="container">
                    <div class="heading-flx">
                        <div> <h2>{{ blog_section.blog_section_title }}</h2></div>
                        <div><a :href=" blog_section.blog_section_button_url"  class="more-btn blog-cta" target="_blank">{{ blog_section.blog_section_button_name }}</a></div>
                   </div>
                <BlogSlider :blogs="blog_data" />
                </div>
            </div>

            
        </div>
    </div>
</template>
<style>
    @media (max-width:480px) {
        .img430 {
            height: 180px !important;
        }
    }
    @media (min-width:481px) {
        /* .img430 {
            height: 430px !important;
        } */
    }
</style>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapState, mapActions } from 'vuex';
import { useMeta } from 'vue-meta'
import { onMounted } from 'vue';
import HomeTabs from '../includes/HomeTabs.vue';
import BlogSlider from '../includes/BlogSlider.vue';
import TestimonialsSlider from '../includes/TestimonialsSlider.vue';
// import axios from 'axios';
// const url = process.env.VUE_APP_API_BASE_URL;
export default {
    name: 'App',
    components: {
    HomeTabs,
    BlogSlider,
    TestimonialsSlider,
  },
    data() {
        return {
            env_data: this.$apiUrl,
            isload: false,
            banner_sec: '',
            works_video_section: '',
            permissioned_pools: '',
            institutional_pools: '',
            lend_usdc_and_usdt: '',
            our_backers: '',
            testimonials: '',
            audited_by: '',
            earn_up: '',
            blog_data: '',
            blog_section: '',
            // banner_sec_img: require('@/assets/images/banner-img.png')
        }
    },
    computed: {
        // ...mapGetters('counterMod', ['personName']),
        ...mapState({home: state => state.webapiMod.home}),
    },
     watch: {
        home() {
            this.banner_sec = this.home.section1_banner_sec;
            this.works_video_section = this.home.works_video_section;
            this.permissioned_pools = this.home.section2_permissioned_pools;
            this.institutional_pools = this.home.section_institutional_pools;
            this.lend_usdc_and_usdt = this.home.section_lend_usdc_and_usdt;
            this.our_backers = this.home.section_our_backers;
            this.testimonials= this.home.testimonials;
            this.blog_data = this.home.blog_data;
            this.blog_section = this.home.blog_section;
            
            this.audited_by = this.home.section_audited_by;
            
            this.earn_up = this.home.section_earn_up;
            setTimeout(function() {              
                document.getElementById('video-iframe').style.display = 'block';
            }, 1000); 
            this.isload = true;
            // this.isloading=false;
        }
    },
    methods: {
        ...mapActions({ GetHome: "webapiMod/GetHome", }),
        getHeight(val) {
            return val + 'px !important';
        }
    },
    mounted() {
        // console.log(this.env_data);
    },
    created() {
        // console.log('testing 2nd render');
        // console.log(this.home);
        this.GetHome();
        // if (this.home.length == 0) {
        //     this.GetHome();
        // } else {
        //     this.banner_sec = this.home.section1_banner_sec;
        //     this.works_video_section = this.home.works_video_section;
        //     this.permissioned_pools = this.home.section2_permissioned_pools;
        //     this.institutional_pools = this.home.section_institutional_pools;
        //     this.lend_usdc_and_usdt = this.home.section_lend_usdc_and_usdt;
        //     this.our_backers = this.home.section_our_backers;
        //     this.testimonials= this.home.testimonials;
        //     this.audited_by = this.home.section_audited_by;
        //     this.blog_data = this.home.blog_data;
        //     this.blog_section = this.home.blog_section;
        //     this.earn_up = this.home.section_earn_up;
        //     this.isload = true;
        //     this.GetHome();
        // }
    },
    setup() {
        const { meta } = useMeta({});
        console.log(meta);
        console.log(window.location.href);
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            // let response = await fetch('https://ricoman.bigwavedevelopment.com/back-end/wp-json/wp/v2/pages?slug=home-page');
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=home');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
}
</script>
